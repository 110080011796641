/* * reset css */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* * btns style */
.btn{
  border: 1px solid #fafafa;
  border-radius: 50%;
  background-color: transparent;
  color: #fafafa;
  width: 40px;
  height: 40px;
}
.btn svg{
  width: 100%;
  height: 100%;
}


/* * flex global style */
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}



/* * more style */
.btn-wraper{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  position: fixed;
  right: 0;
  z-index: 15;
  padding: 10px 20px;
  box-sizing: border-box;
}
.btn-wraper>*{
  margin: 0 10px;
}

.webcam{
  position: "absolute";
  margin-left: 'auto';
  margin-right: "auto";
  left: 0;
  right: 0;
  top: 0;
  text-align: 'center';
  z-index: 9;
  height: "100vh";
  width: "100%";
  object-position: left;
  object-fit: cover;
}